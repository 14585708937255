import React, { useEffect } from 'react';

import { func, shape } from 'prop-types';
import {
  IntlProvider,
  FormattedMessage,
  FormattedDate,
  FormattedDateTimeRange,
  createIntl,
  createIntlCache,
  injectIntl,
  useIntl,
} from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ConfigurationProvider } from '../context/configurationContext';
import { RouteConfigurationProvider } from '../context/routeConfigurationContext';
import routeConfiguration from '../routing/routeConfiguration';
import loadable from '@loadable/component';
import moment from 'moment';
import 'moment/locale/en-gb'; // Import en-GB locale
import 'moment/locale/fr'; // Import fr locale
// Note: we import React Intl directly from dist directory, because
// by default the library assumes the usage of ES modules
// and that don't work with server-side rendering.
// https://github.com/formatjs/formatjs/issues/1499#issuecomment-570151879

import { languagesHelper as language } from '../helpers/languages';

const filterMessages = (jsonData, language) => {
  const originalKeys = Object.keys(jsonData);
  const filteredKeys = originalKeys.filter(k => {
    const lastThreeCharacters = k.substring(k.length - 3);
    return lastThreeCharacters === `.${language}`;
  });

  const filteredData = {};

  filteredKeys.forEach(key => {
    filteredData[key] = jsonData[key];
  });

  return filteredData;
};

const mapMessages = (jsonData, language) => {
  const originalKeys = Object.keys(jsonData);
  const mappedKeys = originalKeys.map(k => k.substring(0, k.length - 3));

  const mappedData = {};

  mappedKeys.forEach(key => {
    mappedData[key] = jsonData[`${key}.${language}`];
  });

  return mappedData;
};

const ReduxConnectedIntlProviderComponent = props => {
  const { children, messages, ...rest } = props;

  let currentLocale = language.get();

  const filteredMessages = filterMessages(messages, currentLocale);
  const mappedMessages = mapMessages(filteredMessages, currentLocale);

  const locale = currentLocale === 'en' ? 'en' : 'fr';

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);
  return (
    <IntlProvider {...rest} locale={locale} messages={mappedMessages}>
      {children}
    </IntlProvider>
  );
};
const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser
  };
};
const ReduxConnectedIntlProvider = compose(connect(mapStateToProps))(
  ReduxConnectedIntlProviderComponent
);

const intlShape = shape({
  formatDate: func.isRequired,
  formatDateTimeRange: func.isRequired,
  formatMessage: func.isRequired,
  formatNumber: func.isRequired,
  formatPlural: func.isRequired,
  formatRelativeTime: func.isRequired,
  formatTime: func.isRequired,
});
export {
  IntlProvider,
  ReduxConnectedIntlProvider,
  FormattedMessage,
  FormattedDate,
  FormattedDateTimeRange,
  createIntl,
  createIntlCache,
  injectIntl,
  useIntl,
  intlShape,
};
