import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defaultLocale } from '../../intl';
import { OutsideClickHandler } from '../../components';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import enFlag from './icons/enFlagIcon.png';
import frFlag from './icons/frFlagIcon.png';

import css from './MultiLanguageSelect.module.css';

const MultiLanguageSelectComponent = props => {
  const { onUpdateProfile, currentUser } = props;

  const currentLocale = typeof window !== 'undefined' && window.localStorage.getItem('parkerLanguage') || defaultLocale;
  
  const [isOpen, setIsOpen] = useState(false)

  //set language to saved value if any
  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.localStorage.getItem('parkerLanguage') || currentLocale;

    if (currentLocale !== savedLanguageMaybe) {
      window.localStorage.setItem('parkerLanguage', currentUser?.attributes?.profile?.protectedData?.language);
    }
  }, []);

  const handleLanguageSwitch = async (lang) => {
  
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    }
  
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('parkerLanguage', lang);
    }
  
    if (typeof document !== 'undefined') {
      document.location.reload(true);
    }

    setIsOpen(false);
  };


  let flagLabel = frFlag;
  switch (currentLocale) {
    case 'en':
      flagLabel = enFlag;
      break;
    case 'fr':
      flagLabel = frFlag;
      break;
  }

  return (
    <div className={css.langSwitchWrapper}>

      <OutsideClickHandler
        className={css.languagesHolderDesktop}
        onOutsideClick={() => setIsOpen(false)}
      >
        <div className={css.languages}>
          <div className={css.langSwitchIcon} onClick={() => setIsOpen(true)}>
            {/* <IconGlobe /> */}
            <img src={flagLabel} className={css.icon} />
          </div>

          {isOpen && (
            <ul className={css.langSwitchContent}>
              <li
                className={classNames(css.labelWrapper, { [css.labelWrapperActive]: currentLocale === 'en' })}
                onClick={() => handleLanguageSwitch('en')}
              >
                <img src={enFlag} className={css.icon} />
                <span className={css.label}>
                  {/* EN */}
                </span>
              </li>
              <li
                className={classNames(css.labelWrapper, { [css.labelWrapperActive]: currentLocale === 'fr' })}
                onClick={() => handleLanguageSwitch('fr')}
              >
                <img src={frFlag} className={css.icon} />
                <span className={css.label}>
                  {/* FR */}
                </span>
              </li>
            </ul>
          )}
        </div>

      </OutsideClickHandler>

      <div className={css.languagesHolderMobile}>
        <ul className={css.langSwitchContent}>
          <li
            className={classNames(css.labelWrapper, { [css.labelWrapperActive]: currentLocale === 'en' })}
            onClick={() => handleLanguageSwitch('en')}
          >
            <img src={enFlag} className={css.icon} />
            <span className={css.label}>
              {/* EN */}
            </span>
          </li>
          <li
            className={classNames(css.labelWrapper, { [css.labelWrapperActive]: currentLocale === 'fr' })}
            onClick={() => handleLanguageSwitch('fr')}
          >
            <img src={frFlag} className={css.icon} />
            <span className={css.label}>
              {/* FR */}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const MultiLanguageSelect = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MultiLanguageSelectComponent);

export default MultiLanguageSelect;
